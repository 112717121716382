import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    apiToken: '',
    usuario: {},
    caixa: {},
    empresa: {},
    vendedor: {},
    splashScreen: false,
    logErro: [],
    email: '',
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_USUARIO (state, user) {
      state.usuario = user
    },
    SET_EMPRESA (state, empresa) {
      state.empresa = empresa
    },
    SET_LOGOUT (state) {
      state.usuario = null
      state.empresa = null
      state.caixa = null
      state.apiToken = ''
    },
    SET_CAIXA (state, caixa) {
      state.caixa = caixa
    },
    SET_VENDEDOR (state, vendedor) {
      state.vendedor = vendedor
    },
    setSplashScreen (state, payload) {
      state.splashScreen = payload
    },
    setLogErro (state, payload) {
      state.logErro.push(payload)
    },
    SET_EMAIL (state, email) {
      state.email = email
    },
  },
  getters: {
    ApiToken: state => state.apiToken,
    usuario: state => state.usuario,
    empresa: state => state.empresa,
    caixa: state => state.caixa,
    splashScreen: state => state.splashScreen,
    logErro: state => state.logErro,
    email: state => state.email,
    vendedor: state => state.vendedor,
  },
  actions: {

  },
  plugins: [createPersistedState()],
})
