import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)
const Home = () => import('@/views/dashboard/pages/home.vue')
const Login = () => import('@/views/dashboard/pages/login.vue')
const Pedido = () => import('@/views/dashboard/pages/transacoes/pedido/pedido.vue')
const ListaPedidos = () => import('@/views/dashboard/pages/transacoes/pedido/listapedidos.vue')
const PedidoPwa = () => import('@/views/dashboard/pages/transacoes/pedido/pedidopwa.vue')
const Caixa = () => import('@/views/dashboard/pages/cadastro/Caixa.vue')
const Impressora = () => import('@/views/dashboard/pages/cadastro/Impressora.vue')
const LocalImpressao = () => import('@/views/dashboard/pages/cadastro/LocalImpressao.vue')
const RevisaoOrcamento = () => import('@/views/dashboard/pages/transacoes/RevisaoOrcamento.vue')
const ListaUsuario = () => import('@/views/dashboard/pages/transacoes/usuario/listausuario.vue')
const PosicaoFisico = () => import('@/views/dashboard/pages/relatorios/PosicaoFisico.vue')
const SepararMaterial = () => import('@/views/dashboard/pages/transacoes/expedicao/SepararMaterial.vue')
const SepararItemPedido = () => import('@/views/dashboard/pages/transacoes/expedicao/SepararItemPedido.vue')
const SepararMaterialSimples = () => import('@/views/dashboard/pages/transacoes/expedicao/SeparaMaterialSimples')
const RecebimentoCaixa = () => import('@/views/dashboard/pages/transacoes/caixa/recebcaixa.vue')
const EstoqueAcompanhamento = () => import('@/views/dashboard/pages/relatorios/estoqueacompanhamento.vue')
const RecebeCaixaRota = () => import('@/views/dashboard/pages/transacoes/expedicao/RecebeCaixaRota.vue')
const Totem = () => import('@/views/dashboard/pages/transacoes/avaliacoes/totem.vue')
const DevolucaoMaterial = () => import('@/views/dashboard/pages/transacoes/expedicao/devolucaoMaterial.vue')
const AjusteFisico = () => import('@/views/dashboard/pages/transacoes/estoque/ajustefisico.vue')
const PixRotaConsulta = () => import('@/views/dashboard/pages/consultas/PixRotaConsulta.vue')
const ManifestacaoDestinatario = () => import('@/views/dashboard/pages/transacoes/notafiscal/manifestacaodestinatario/mandestinatario.vue')
const GerenciaDashboad = () => import('@/views/dashboard/pages/gerencia/dashboard.vue')
const ListaPedidoVendedor = () => import('@/views/dashboard/pages/transacoes/pedido/listapedidos-vendedor')

// EXPEDICAO
const ExpedicaoRequisicaoNova = () => import('@/views/dashboard/pages/transacoes/expedicao/listaRequicao.vue')

const rotas = [{
  path: '/',
  component: Login,
  redirect: '/login',
},
{
  path: '*',
  redirect: '/',
},
{
  path: '/login',
  component: Login,
  name: 'login',
},
{
  path: '/v1',
  component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        // {
        //   name: 'Dashboard',
        //   path: '/v1/dashboard',
        //   component: () => import('@/views/dashboard/Dashboard'),
        // },
        {
          name: 'home',
          path: '/',
          component: Home,
        },
        {
          name: 'Pedido',
          path: 'Pedido',
          component: Pedido,
        },
        {
          name: 'PedidoPwa',
          path: 'PedidoPwa',
          component: PedidoPwa,
        },
        {
          name: 'Caixa',
          path: 'Caixa',
          component: Caixa,
        },
        {
          name: 'Impressora',
          path: 'Impressora',
          component: Impressora,
        },
        {
          name: 'LocalImpressao',
          path: 'LocalImpressao',
          component: LocalImpressao,
        },
        {
          name: 'RevisaoOrcamento',
          path: 'RevisaoOrcamento',
          component: RevisaoOrcamento,
        },
        {
          name: 'ListaUsuario',
          path: 'ListaUsuario',
          component: ListaUsuario,
        },
        {
          name: 'PosicaoFisico',
          path: 'PosicaoFisico',
          component: PosicaoFisico,
        },
        {
          name: 'ListaPedidos',
          path: 'ListaPedidos',
          component: ListaPedidos,
        },
        {
          name: 'SepararMaterial',
          path: 'Expedicao/SepararMaterial',
          component: SepararMaterial,
        },
        {
          name: 'SepararItemPedido',
          path: 'Expedicao/SepararItemPedido',
          component: SepararItemPedido,
        },
        {
          name: 'RecebimentoCaixa',
          path: 'Transacoes/caixa/recebcaixa',
          component: RecebimentoCaixa,
        },
        {
          name: 'ExpedicaoRequisicaoNova',
          path: 'Expedicao/novaRequicao',
          component: ExpedicaoRequisicaoNova,
        },
        {
          name: 'SeparaMaterialSimples',
          path: 'Expedicao/SeparaMaterialSimples',
          component: SepararMaterialSimples,
        },
        {
          name: 'EstoqueAcompanhamento',
          path: 'Relatorio/Estoque/EstoqueAcompanhamento',
          component: EstoqueAcompanhamento,
        },
        {
          name: 'RecebeCaixaRota',
          path: 'Expedicao/RecebeCaixaRota',
          component: RecebeCaixaRota,
        },
        {
          name: 'Totem',
          path: 'Transacoes/avaliacoes/totem',
          component: Totem,
        },
        {
          name: 'DevolucaoMaterial',
          path: 'Expedicao/DevolucaoMaterial',
          component: DevolucaoMaterial,
        },
        {
          name: 'AjusteFisico',
          path: 'Transacoes/Estoque/AjusteFisico',
          component: AjusteFisico,
        },
        {
          name: 'PixRotaConsulta',
          path: 'consultas/PixRotaConsulta',
          component: PixRotaConsulta,
        },
        {
          name: 'ManifestacaoDestinatario',
          path: 'Notafiscal/Manifestacaodestinatario/mandestinatario',
          component: ManifestacaoDestinatario,
        },
        {
          name: 'ListaPedidosVendedor',
          path: 'Pedido/ListaPedido/Vendedor',
          component: ListaPedidoVendedor,
        },
        {
          name: 'GerenciaDashboad',
          path: 'dashboad',
          component: GerenciaDashboad,
        },
      ],
    },
 ]

 // configure router
const router = new Router({
  routes: rotas, // short for routes: routes
  // linkActiveClass: 'nav-item active',
  // base: process.env.BASE_URL,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  // EM TODA MUDANÇA DE ROTA VERIFICA SE JA VALIDOU O EMAIL
  if (to.path !== '/login' && (typeof (store.getters.usuario.cdusuario) === 'undefined')) next('/login')
  else next()

  next()
  // typeof(store.getters.user.user_type)=='undefined'
  // next()
})

export default router

// export default new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: [
//     {
//       path: '/',
//       component: () => import('@/views/dashboard/Index'),
//       children: [
//         // Dashboard
//         {
//           name: 'Dashboard',
//           path: '',
//           component: () => import('@/views/dashboard/Dashboard'),
//         },
//         {
//           name: 'Pedido',
//           path: 'Pedido',
//           component: Pedido,
//         },
//         {
//           name: 'PedidoPwa',
//           path: 'PedidoPwa',
//           component: PedidoPwa,
//         },
//         {
//           name: 'Caixa',
//           path: 'Caixa',
//           component: Caixa,
//         },
//       ],
//     },
//   ],
// })
